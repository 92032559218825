var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.numbers),function(number,i){return _c('tr',{key:number.unit},[_c('td',{staticClass:"text-right pl-0 pr-2",class:{
            'border-bottom--light': _vm.isApplyBorder('light', i),
            'border-bottom--dark': _vm.isApplyBorder('dark', i)
          }},[_vm._v(" "+_vm._s(number.unit)+" ")]),_c('td',{staticClass:"text-right px-0",class:{
            'border-bottom--light': _vm.isApplyBorder('light', i),
            'border-bottom--dark': _vm.isApplyBorder('dark', i)
          }},[_vm._v(" "+_vm._s(_vm.toCommaSeparated({ number: number.integer }))+" ")]),_c('td',{staticClass:"text-left px-0",class:{
            'border-bottom--light': _vm.isApplyBorder('light', i),
            'border-bottom--dark': _vm.isApplyBorder('dark', i)
          }},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(number.fraction !== '0'),expression:"number.fraction !== '0'"}]},[_vm._v(" "+_vm._s(number.sep)+_vm._s(number.fraction)+" ")])])])}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }