<template>
  <v-simple-table dense>
    <template v-slot:default>
      <tbody>
        <tr v-for="(number, i) in numbers" :key="number.unit">
          <td
            :class="{
              'border-bottom--light': isApplyBorder('light', i),
              'border-bottom--dark': isApplyBorder('dark', i)
            }"
            class="text-right pl-0 pr-2"
          >
            {{ number.unit }}
          </td>
          <td
            :class="{
              'border-bottom--light': isApplyBorder('light', i),
              'border-bottom--dark': isApplyBorder('dark', i)
            }"
            class="text-right px-0"
          >
            {{
              toCommaSeparated({
                number: number.integer
              })
            }}
          </td>
          <td
            :class="{
              'border-bottom--light': isApplyBorder('light', i),
              'border-bottom--dark': isApplyBorder('dark', i)
            }"
            class="text-left px-0"
          >
            <span v-show="number.fraction !== '0'"> {{ number.sep }}{{ number.fraction }} </span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from "vuex";
import Big from "big.js";

Big.NE = -Infinity;
Big.PE = Infinity;

export default {
  name: "DataConverterUnitTable",
  props: {
    unitBitOrByte: {
      type: String,
      required: true
    },
    units: {
      type: Array,
      required: true
    },
    bits: {
      type: Big,
      required: true
    }
  },
  data() {
    return {
      base: new Big(2 ** 10),
      bitsInByte: new Big(8)
    };
  },
  computed: {
    ...mapGetters(["languageDefault", "toCommaSeparated"]),
    numbers() {
      return this.units.map((unit, exponent) => {
        const number = this.getNumber(this.unitBitOrByte, exponent);
        return this.getNumberFormatted(unit, number);
      });
    },
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled("DataConverter");
    }
  },
  methods: {
    getNumberFormatted(unit, number) {
      const [integer, fraction = "0"] = number.toString().split(".");
      // noinspection JSStringConcatenationToES6Template
      return {
        unit: unit + (number === "1" ? "" : "s"),
        integer,
        fraction,
        group: ",",
        sep: "."
      };
    },
    getNumber(unit, exponent) {
      const bits = this.bits.div(this.base.pow(exponent));
      if (unit === "byte") {
        return bits.div(this.bitsInByte);
      }
      return bits;
    },
    isApplyBorder(theme, exponent) {
      if (exponent < this.units.length - 1) {
        if (theme === "light") {
          return !this.isDarkModeEnabled;
        }
        return this.isDarkModeEnabled;
      }
    }
  }
};
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.border-bottom--light {
  border-bottom: 1px solid black !important;
}

/*noinspection CssUnusedSymbol*/
.border-bottom--dark {
  border-bottom: 1px solid white !important;
}
</style>
